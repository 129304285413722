import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { Stats } from "@react-three/drei";
import Button from "@material-ui/core/Button";

import VisitorCounter from "./VisitorCounter";
import UploadListener from "./UploadListener";
// import Saen from "./effects/Sandbox/StainEffect";
// import Verzweigen from "./effects/Sandbox/Verzweigen";
// import Bluehen from "./effects/Sandbox/Phylotaxis";
// import Kreuzen from "./effects/Kreuzen";
// import Keimen from "./effects/Sandbox/Welken";
// import Welken from "./effects/Sandbox/NewWelken";
import Saen from "./effects/Sandbox/StainEffect";
import Keimen from "./effects/Sandbox/Verzweigen";
import Verzweigen from "./effects/Sandbox/NewWelken";
import Bluehen from "./effects/Sandbox/Phylotaxis";
import Kreuzen from "./effects/Kreuzen";
import Welken from "./effects/Sandbox/Welken";
import RandomWalk0 from "./effects/Sandbox/RandomWalk0";
import RaysOfBlindingLight from "./effects/Sandbox/RaysOfBlindingLight";
import R3F from "./R3F";
import HyperCam from "./HyperCam";
import PhotoBooth from "./PhotoBooth";
import QRCode from "react-qr-code";
import GardenGallery from "./GardenGallery/index";
import Downloader from "./Downloader";
import SeedPicker from "./SeedPicker";
import EffectDebugger from "./EffectDebugger";
import People from "./People";
import config from "../config.json";

import { useStore } from "../store";

import { uploadSeed, getUserId, getSeedCount, getUserCount } from "../api";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  color: black;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: black;
  }
`;

const SectionContainer = styled.section`
  margin: 50px;
  background-color: lightgoldenrodyellow;
`;
function Section({ title, children }) {
  const [active, setActive] = useState(false);
  return (
    <SectionContainer>
      <h2>{title}</h2>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setActive(!active);
        }}
      >
        {active ? "deactivate" : "activate"}
      </Button>
      {active && <>{children}</>}
    </SectionContainer>
  );
}

export default function Demo() {
  const userId = useStore((state) => state.userId);
  const currentScroll = useStore((state) => state.currentScroll);
  const scrollDirection = useStore((state) => state.scrollDirection);
  const currentStep = useStore((state) => state.currentStep);
  const setHyperCam = useStore((state) => state.setHyperCam);
  const images = [
    "http://placekitten.com/200/300",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Nick_Cave_in_New_York_City_2009_portrait_by_DS.jpg/340px-Nick_Cave_in_New_York_City_2009_portrait_by_DS.jpg",
    "https://upload.wikimedia.org/wikipedia/commons/3/37/Sus_Barbatus%2C_the_Bornean_Bearded_Pig_%2812616351323%29.jpg",
  ];

  const setUserId = useStore((state) => state.setUserId);
  const [pickedSeed, setPickedSeed] = useState(null);

  let history = useHistory();
  const params = useParams();
  useEffect(() => {
    if (userId) {
      history.push(`/debug/${userId}`);
    }
  }, [userId]);

  // fetching a new user id
  // only happens once
  useEffect(() => {
    if (params.userId) {
      // console.log("got user id via url param", params.userId);
      setUserId(params.userId);
    } else {
      getUserId().then((response) => {
        // console.log("got new user id", response);
        setUserId(response);
      });
    }
  }, []);

  useEffect(() => {
    getSeedCount().then((data) => {
      // console.log("number of seeds", data);
    });
  }, []);
  useEffect(() => {
    getUserCount().then((data) => {
      // console.log("number of users", data);
    });
  }, []);
  // const subscription = useStore.subscribe((value) =>
  //   console.log("demo store changed", value)
  // );
  return (
    <Container>
      <Stats></Stats>
      <People></People>
      <Section title={"upload and effect"}>
        <EffectDebugger></EffectDebugger>
      </Section>
      {/* here is the hypercam, if we set active to true it will load the iframe and then prompt the camera permission popup*/}
      <Section title={"hyper cam"}>
        <Button
          onClick={() => {
            setHyperCam(true);
          }}
        >
          setHyperCam
        </Button>
        <HyperCam url={config.hyperCamUrl}></HyperCam>
      </Section>

      {/* here is a little demo how a visitor counter could be used, it requires to run the node script pasted in the issue on gh */}
      {/* <VisitorCounter></VisitorCounter> */}
      <UploadListener></UploadListener>

      <Section title={"QR"}>
        <QRCode value={`${config.uploadUrl}/${userId}`} />
      </Section>
      <Section title={"photo booth"}>
        <PhotoBooth
          uploadCallback={(data) => {
            uploadSeed(data, userId, 0);
          }}
        ></PhotoBooth>
      </Section>
      <Section title={"store"}>
        <ul>
          <li>userId: {userId}</li>
          <li>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setUserId(Math.floor(Math.random() * 1000));
              }}
            >
              randomize
            </Button>
          </li>
          <li>currentScroll: {currentScroll}</li>
          <li>scrollDirection: {scrollDirection}</li>
          <li>currentStep: {currentStep}</li>
        </ul>
      </Section>

      <Section title={"p5 säen"}>
        {/* and finally a p5 image effect, it will fetch the url passed to it and run some pixel filters on it, once done, it will trigger onFinished */}
        <Saen
          active={true}
          src={
            "https://api.tangled-garden.net/assets/c57e84f1-f502-488b-aead-c13664146809?width=600&height=600"
          }
          width={800}
          height={800}
          onFinished={(data) => {
            // console.log(data);
            // uploadImage(data, userId);
          }}
        ></Saen>
      </Section>

      <Section title={"p5 keimen"}>
        {/* and finally a p5 image effect, it will fetch the url passed to it and run some pixel filters on it, once done, it will trigger onFinished */}
        <Keimen
          active={true}
          src={
            "https://api.tangled-garden.net/assets/c57e84f1-f502-488b-aead-c13664146809?width=600&height=600"
          }
          width={800}
          height={800}
          onFinished={(data) => {
            // console.log(data);
            // uploadImage(data, userId);
          }}
        ></Keimen>
      </Section>

      <Section title={"p5 Verzweigen"}>
        {/* and finally a p5 image effect, it will fetch the url passed to it and run some pixel filters on it, once done, it will trigger onFinished */}
        <Verzweigen
          active={true}
          src={
            "https://api.tangled-garden.net/assets/c57e84f1-f502-488b-aead-c13664146809?width=600&height=600"
          }
          width={800}
          height={800}
          onFinished={(data) => {
            // console.log(data);
            // uploadImage(data, userId);
          }}
        ></Verzweigen>
      </Section>

      <Section title={"p5 blühen"}>
        {/* and finally a p5 image effect, it will fetch the url passed to it and run some pixel filters on it, once done, it will trigger onFinished */}
        <Bluehen
          active={true}
          src={
            "https://api.tangled-garden.net/assets/52926b58-1d69-4b1c-81d3-48433515c2ef?width=600&height=600"
          }
          width={800}
          height={800}
          onFinished={(data) => {
            // console.log(data);
            // uploadImage(data, userId);
          }}
        ></Bluehen>
      </Section>

      <Section title={"p5 kreuzen"}>
        {/* and finally a p5 image effect, it will fetch the url passed to it and run some pixel filters on it, once done, it will trigger onFinished */}
        <Kreuzen
          active={true}
          src={
            "https://api.tangled-garden.net/assets/52926b58-1d69-4b1c-81d3-48433515c2ef?width=600&height=600"
          }
          src2={
            "https://api.tangled-garden.net/assets/a1fc7be4-b11b-483f-aa65-157c61a12003?width=600&height=600"
          }
          width={800}
          height={800}
          onFinished={(data) => {
            // console.log(data);
            // uploadImage(data, userId);
          }}
        ></Kreuzen>
      </Section>

      <Section title={"p5 welken"}>
        {/* and finally a p5 image effect, it will fetch the url passed to it and run some pixel filters on it, once done, it will trigger onFinished */}
        <Welken
          active={true}
          src={
            "https://api.tangled-garden.net/assets/52926b58-1d69-4b1c-81d3-48433515c2ef?width=600&height=600"
          }
          src2={
            "https://api.tangled-garden.net/assets/a1fc7be4-b11b-483f-aa65-157c61a12003?width=600&height=600"
          }
          width={800}
          height={800}
          onFinished={(data) => {
            // console.log(data);
            // uploadImage(data, userId);
          }}
        ></Welken>
      </Section>

      <Section title={"R3F Demo"}>
        <R3F
          active={true}
          src={
            "https://api.tangled-garden.net/assets/51f4a4a7-5a78-49e7-a800-1fe618d802da?width=600&height:600"
          }
          width={800}
          height={800}
          onFinished={(data) => {
            // console.log(data);
          }}
        ></R3F>
      </Section>
      <Section title={"GardenGallery"}>
        <GardenGallery images={images}></GardenGallery>
      </Section>
      <Section title={"Downloader"}>
        <Downloader></Downloader>
      </Section>
      <Section title={"SeedPicker"}>
        <SeedPicker
          pickedSeed={pickedSeed}
          onSeedPicked={(seed) => {
            setPickedSeed(seed);
            // console.log(seed);
          }}
        ></SeedPicker>
      </Section>

      <Section title={"effect sandbox"}>
        {/* <RandomWalk0 active={true} width={500} height={500}></RandomWalk0> */}
        <RaysOfBlindingLight
          active={true}
          src={
            "https://api.tangled-garden.net/assets/7908d190-c7fe-432c-9051-5f917f822d19?width=500&height=500&fit=cover"
          }
          width={800}
          height={800}
        ></RaysOfBlindingLight>
      </Section>
    </Container>
  );
}
