import * as THREE from "three";
import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas, useFrame, useLoader, useThree } from "react-three-fiber";
import { useAspect, OrbitControls } from "@react-three/drei";
import Camera from "./Camera";
import Lights from "./Lights";
import Stage from "./Stage";
import PostProcessing from "./PostProcessing";
import Plant from "./Plant";
import { getSeeds, getAssetUrl } from "../../api";
import { useStore } from "../../store";

const getNumber = (text) => {
  let value = 0;
  for (var i = 0; i < text.length; i++) {
    value += text.charCodeAt(i);
  }
  return value;
};

const getSemiRandom = (inVal) => {
  return (inVal  * 16807 % 2147483647 - 1);
};

const getPosition = ({ date_created, id, height, width, image }) => {
  const maxX = 300;
  const maxZ = 300;
  let x = getNumber(date_created + id + width);
  x = getSemiRandom(x);
  x = x % maxX;
  x = x - maxX / 2;

  let z = getNumber(image.title + image.filesize + id + height / 2);
  //z = getSemiRandom(z+123)*maxZ;
  z = z % maxZ;
  z = z - maxZ / 2;

  let y = image && image.filesize ? image.filesize % 10 : 0;
  y = y - 40;
  // const y = 0;
  return [x, 10, 200 - z];
};
// const subscription = useStore.subscribe((value) =>
//   console.log("gardengallery store changed", value)
// );
export default function GardenGallery({ images }) {
  const [files, setFiles] = useState([]);
  const seed = useStore((state) => state.seed);
  const saen = useStore((state) => state.saen);
  const keimen = useStore((state) => state.keimen);
  const verzweigen = useStore((state) => state.verzweigen);
  const bluehen = useStore((state) => state.bluehen);
  const kreuzen = useStore((state) => state.kreuzen);
  const welken = useStore((state) => state.welken);

  //   fetching all files
  //   happens immediately and then every 10 seconds
  useEffect(() => {
    getSeeds().then((data) => {
      // console.log("fetched images for gallery", data);
      if (data) {
        setFiles(data.slice(0, Math.min(100, data.length)));
      } else {
        console.error("could not get all seeds", data);
      }
    });
    const id = setInterval(() => {
      if (files.length === 0) {
        getSeeds().then((data) => {
          if (data) {
            setFiles(data.slice(0, Math.min(100, data.length)));
          } else {
            console.error("could not get all seeds", data);
          }
        });
      }
    }, 10000);
    return () => {
      // clearInterval(id);
    };
  }, [setFiles]);

  return (
    <Canvas
      style={{
        height: `80vh`,
        width: `90vw`,
      }}
      camera={{ fov: 75, position: [0, 50, 450] }}
    >
      <OrbitControls
        enablePan={true}
        enableZoom={true}
        enableRotate={true}
        zoomSpeed={-1}
      />
      <Lights></Lights>
      <Stage></Stage>
      <Camera></Camera>

      <Suspense fallback={null}>
        {files &&
          files.map((file, index) => {
            return (
              <Plant
                key={index}
                url={getAssetUrl(file.image.id, 500, 500)}
                position={getPosition(file)}
              ></Plant>
            );
          })}
        {seed && (
          <Plant
            url={getAssetUrl(seed.id, 500, 500)}
            position={[-10, 0, 10]}
            highlight={true}
          ></Plant>
        )}
        {saen && (
          <Plant
            url={getAssetUrl(saen.id, 500, 500)}
            position={[-20, 0, 20]}
            highlight={true}
          ></Plant>
        )}
        {keimen && (
          <Plant
            url={getAssetUrl(keimen.id, 500, 500)}
            position={[-10, 0, -10]}
            highlight={true}
          ></Plant>
        )}
        {verzweigen && (
          <Plant
            url={getAssetUrl(verzweigen.id, 500, 500)}
            position={[0, 0, -15]}
            highlight={true}
          ></Plant>
        )}
        {bluehen && (
          <Plant
            url={getAssetUrl(bluehen.id, 500, 500)}
            position={[-40, 0, -10]}
            highlight={true}
          ></Plant>
        )}
        {kreuzen && (
          <Plant
            url={getAssetUrl(kreuzen.id, 500, 500)}
            position={[20, 0, -15]}
            highlight={true}
          ></Plant>
        )}
        {welken && (
          <Plant
            url={getAssetUrl(welken.id, 500, 500)}
            position={[40, 0, 5]}
            highlight={true}
          ></Plant>
        )}
        <PostProcessing></PostProcessing>
      </Suspense>
    </Canvas>
  );
}
