import Raect from "react";
import { Canvas, useFrame, useLoader, useThree } from "react-three-fiber";
import * as THREE from "three";
export default function Camera() {
  const min = 5;
  const max = 600;
  useFrame((state) => {
    // console.log(state.camera.;
    // state.camera.position.z = 50 + Math.sin(state.clock.getElapsedTime()) * 30;
    if (state.camera.position.z < min) {
      state.camera.position.z = min;
      state.camera.updateProjectionMatrix();
    } else if (state.camera.position.z > max) {
      state.camera.position.z = max;
      state.camera.updateProjectionMatrix();
    }

    const rotationX = state.camera.rotation._x;
    const rotationY = state.camera.rotation._y;
    const rotationZ = state.camera.rotation._z;

    // console.log(rotationX);
    // const minXRot = 0.2;
    // const maxXRot = -0.5;
    // if (rotationX > minXRot) {
    //   state.camera.setRotationFromAxisAngle(
    //     new THREE.Vector3(1.0, 0.0, 0.0),
    //     minXRot
    //   );
    // }
    // if (rotationX < maxXRot) {
    //   state.camera.setRotationFromAxisAngle(
    //     new THREE.Vector3(1.0, 0.0, 0.0),
    //     maxXRot
    //   );
    // }
  });
  return null;
}
