import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
// import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Image from "./Utils/image";

const Container = styled.div`
  background-color: black;
  color: white;
  width: 100vw;
  height: 100%;
  text-align: center;
  display: block;
  position: fixed;
  .MuiCheckbox-root {
    color: orangered;
    left: 0;
    margin-left:-42px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: white;
  }
`;

const DataPolicy = styled.section`
  text-align: left;
  font-family: "reg";
  left: 10vw;
  margin-left: 30px;
  color: white;
  z-index: 1;
  bottom: 80px;
  position: fixed;
  span {
    text-decoration: underline;
    z-index: 10;
  }
  @media (min-width: 600px) {
    display: none;
  }
`;
const Button = styled.button`
  font-size: 22px;
  background-color: rgba(255, 255, 255, 0.1);
  position: fixed;
  bottom: 30px;
  width: 80vw;
  margin-left: 10vw;
  border: solid white 1px;
  color: white;
  font-family: "reg";
  height: 50px;
  left: 0;
`;

const Success = styled.div`
  position: absolute;
  bottom: 2vh;
  width: 80vw;
  font-size: 20px;
  font-family: "reg";
  margin: 10vw;
`;

const Top = styled.section``;
const Center = styled.section``;
const Bottom = styled.section`
  position: absolute;
  bottom: 0;
  height: 60vh;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100vw;
  color: black;
  padding: 15px 60px 15px 15px;
  box-sizing: border-box;
  text-align: left;
  overflow-y: scroll;
  @media (min-width: 600px) {
    display: none;
  }
`;

const ImageBooth = styled.img`
  border: 1px solid white;
  text-align: center;
  top: 40vh;
  width: 60vw;
  height: 60vw;
  object-fit: cover;
  position: fixed;
  left: 20vw;
`;

const Cross = styled.div`
  position: fixed;
  right: 15px;
  bottom: calc(60vh - 45px);
  width: 30px;
  height: 30px;
  background-image: url("http://api.tangled-garden.net/assets/1e5dfe66-0e90-4f21-8b19-c49d9ba80481");
  background-size: contain;
  background-repeat: no-repeat;
`;

const DropZone = styled.div`
  border: 1px solid white;
  padding: 10px;
  text-align: center;
  font-family: "reg";
  font-size: 4vw;
  top: 40vh;
  width: 80vw;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  margin-left: 10vw;
  box-sizing: border-box;
  left: 0;
  span {
    background-image: url("http://api.tangled-garden.net/assets/1e5dfe66-0e90-4f21-8b19-c49d9ba80481");
    background-size: contain;
    background-repeat: no-repeat;
    border: solid 1px white;
  }
`;

export default function PhotoBooth({ uploadCallback }) {
  const { userId } = useParams();
  const [uploaded, setUploaded] = useState(false);
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [consent, setConsent] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const upload = useCallback(
    (file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        // console.log(reader.result);
        setImgSrc(reader.result);
      };
      reader.onerror = function (error) {
        // console.log("Error: ", error);
      };
    },
    [setImgSrc]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => {
        upload(file, userId);
        // console.log(URL.createObjectURL(file));
      });
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Container>
      <div className="upload__computer">
        Please upload your picture from a phone.
      </div>
      <div className="mobile__plants">
        <Image id="d589e744-92f4-4617-b8c6-7710fde86d98" />
      </div>

      {uploaded && (
        <>
        <Top>
        <h1 className="mobile__title">
          The Garden
          <br />
          of Tangled Data
        </h1>
      </Top>
        <Success>
          Thanks for contributing, your seed is on its way to the Tangled Garden
          ecosystem. It will be there in a few seconds.
        </Success>
        </>
      )}
      {!uploaded && (
        <>
          <Top>
            <h1 className="mobile__title">
              The Garden
              <br />
              of Tangled Data
            </h1>
          </Top>
          <Center>
            {imgSrc && <ImageBooth src={imgSrc}></ImageBooth>}
            {!imgSrc && (
              <DropZone {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Choose a picture to upload to the garden</p>
              </DropZone>
            )}
          </Center>

          <DataPolicy>
          <Checkbox
              checked={consent}
              onChange={(e, checked) => {
                console.log(checked);
                setConsent(checked);
              }}
            />
            agree to{" "}
            <span
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              data policy
            </span>
          </DataPolicy>
          {showMore && (
            <Bottom>
              <Cross
                onClick={() => {
                  setShowMore(false);
                }}
              ></Cross>
              Das Bild, das Sie hochladen, kann von anderen Besucher:innen der Garden-Website gesehen werden. Bitte laden Sie keine Bilder hoch, die die Rechte oder Gefühle Dritter verletzen könnten.

              <br/><br/>Wir verwenden Ihr Bild nur auf dieser Website und keinesfalls für kommerzielle Nutzung.
            </Bottom>
          )}

          <Button
            onClick={(e) => {
              uploadCallback(imgSrc, userId);
              setTimeout(() => {
                setUploaded(true);
              }, 3000);
            }}
            color="primary"
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={!consent}
          >
            Upload
          </Button>
        </>
      )}
    </Container>
  );
}
