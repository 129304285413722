import React from "react";

function GroundPlane() {
  return (
    <mesh receiveShadow rotation={[5, 0, 0]} position={[0, 0, 0]}>
      {" "}
      <planeBufferGeometry attach="geometry" args={[600, 600]} />{" "}
      <meshStandardMaterial attach="material" color="darkgreen" />{" "}
    </mesh>
  );
}
function BackDrop() {
  return (
    <mesh receiveShadow position={[0, -1, -5]}>
      {" "}
      <planeBufferGeometry attach="geometry" args={[600, 600]} />{" "}
      <meshStandardMaterial attach="material" color="rgb(20,242,40)" />{" "}
    </mesh>
  );
}

export default function Stage() {
  return (
    <>
      <GroundPlane />
      <BackDrop />
    </>
  );
}
