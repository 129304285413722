import React from "react";
import Sketch from "react-p5";
import { RGBA_ASTC_10x10_Format } from "three";
import { v4 as uuidv4 } from "uuid";
import Container from "../Container";
import {
  getIndex,
  getRed,
  getGreen,
  getBlue,
  getBrightness,
  setColor,
  createSetup,
  loadPixelsAndIterate,
  getBase64,
  wrap,
  AnimationManager,
  makeNoiseMap,
  minmax,
} from "../helpers";

let frameCounter = 0;
const id = "id_" + uuidv4();
var hasFinished = false;

/* p5 global vars */
let anMan;
let img1ref;

let img2ref;
let dodge;
let burn;
let burn2;
let count;
let frameCount = 0;
/**
 * take two images, intersect them by noise, fake animate the process
 *
 * @param {boolean} active
 * @param {*} src
 * @param {int} width
 * @param {int} height
 * @param {function} onFinished
 * @param {int} runtime - milliseconds until animation is "finished"
 * @param {int} frameDivider - animation is called every nth frame
 * @param {int} noiseScalar - noise Size Scalar
 */
export default function P5({
  active,
  src,
  width,
  height,
  onFinished,
  src2,
  runtime = 30000,
  frameDivider = 1,
  noiseScalar = 3,
}) {
  const preload = (p5) => {
    // fetch imgs, handle only one img somewhat gracefully
    img1ref = p5.loadImage(src);
    dodge = p5.loadImage("https://api.tangled-garden.net/assets/3ef0a70c-35c7-43fd-993d-c80235af8ae6?width=800&height=800&fit=cover");
    burn = p5.loadImage("https://api.tangled-garden.net/assets/00850b08-f76e-4aef-a2a3-10b97ec0103d?width=800&height=800&fit=cover");
    burn2 = p5.loadImage("https://api.tangled-garden.net/assets/70201cb9-7957-4ccc-9761-7280930e093e?width=800&height=800&fit=cover");
  };

  const setup = (p5, canvasParentRef) => {
    // barebones p5
    // createSetup(width, height, src)(p5, canvasParentRef);
    p5.createCanvas(width, height).parent(canvasParentRef);
    // console.log(canvasParentRef);
    p5.pixelDensity(1);
    img1ref.resize(width, height);
    dodge.resize(width, height);
    burn.resize(width, height);
    burn2.resize(width, height);
    // images and buffers
    // animation management
    anMan = new AnimationManager(p5, runtime);
    p5.image(img1ref, 0, 0, width, height);
    img1ref.blend(dodge, 0, 0, 1270, 800, 0, 0, 1270, 800, p5.DODGE)
    img1ref.blend(burn, 0, 0, 1270, 800, 0, 0, 1270, 800, p5.BURN)
    burn2.filter(p5.BLUR, 2)
    img1ref.blend(burn2, 0, 0, 1270, 800, 0, 0, 1270, 800, p5.BURN)
    img2ref = p5.createImage(800, 800)
    img2ref = img1ref.get()
    img2ref.filter(p5.ERODE, 8)
    img2ref.mask(burn)
    count = 0
  };
  const draw = (p5) => {
    if (!active) return 0; // if not active skip block
    if (frameDivider != 1) {
      // check if framedivider is in effect
      frameCounter++;
      if (frameCounter % frameDivider != 0) {
        // if not on "active" frame return
        return 1;
      }
    }
    anMan.start();
    let animationHead = anMan.update();

    // noiseParams.zOffset = animationHead * 5;
    // noiseParams.noiseScalar = 1 + animationHead * 30;

    // noiseMap = makeNoiseMap(p5, noiseParams);
    /* 
    creative part start
    */
    let offset = 0
    let xSize = img1ref.width
    let ySize = img1ref.height
    let posY = 0;
    if (frameCount % 3 == 0) {
      posY = p5.random(-1, 2)
    }
    if (count > 300) {
      count = 0;
    }
    if (count > 200) {
      xSize = xSize * 1.5
      ySize = ySize * 1.5
      offset = 200
    }
    p5.image(img1ref, 0 - offset, posY - offset, xSize, ySize)
    for (var x = 0; x < 17; x += 4) {
      for (var y = 0; y < img1ref.height; y += 4) {
        p5.fill(p5.random(255))
        p5.rect(x, y, 4, 4)
      }
    }
    count += 1;
    frameCount++;
    /*
    creative part end
    */

    if (animationHead >= 1 && !hasFinished) {
      hasFinished = true;
      // if past runtime trigger finished event
      if (onFinished) {
        onFinished(getBase64(id));
      }
      return 2;
    }
    return 3;
  };
  const debugDraw = (p5) => {
    // console.log({
    //   active,
    //   src,
    //   src2,
    // });
    // console.log(draw(p5));
    draw(p5);
  };

  return (
    <Container width={width} height={height} id={id}>
      <Sketch preload={preload} setup={setup} draw={debugDraw} />
    </Container>
  );
}
