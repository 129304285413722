import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../store";
import { getAssetUrl } from "../../api";

import Image from "../Utils/image";
import Video from "../Utils/Video";
import Wrapper from "../Utils/Wrapper";

// == Import
import "./styles.scss";
import "./circle.scss";

// == Composant
const Introduction = () => {
  const devMode = useStore((state) => state.devMode);
  const videoA = useStore((state) => state.videoA);
  const videoB = useStore((state) => state.videoB);
  const setStarted = useStore((state) => state.setStarted);
  const setVideoA = useStore((state) => state.setVideoA);
  const setScrollLocked = useStore((state) => state.setScrollLocked);
  // const setVideoB = useStore((state) => state.setVideoB);

  const start = () => {
    setStarted(true);
    document.querySelector(".intro__click__square p").innerHTML = "Now scroll down";
    document.querySelector(".intro__introVideo video").play();
    setTimeout(() => go(), 1000);
  };

  const go = () => {
    document.querySelector(".intro__click").style.opacity = "0";
    document.querySelector(".intro__introVideo video").muted = false;
    document.querySelector(".section--intro").style.display = "block";
    document.querySelector(".intro__introVideo").style.display = "none";
    document.querySelector(".intro__introVideo").style.opacity = "0";
    document.querySelector(".intro__introVideo").style.width = "100vw";
    document.querySelector(".intro__introVideo").style.left = "0";
    document.querySelector(".intro__introVideo").style.borderRadius = "0";
    setScrollLocked(false);
    setTimeout(() => {
      document.querySelector(".intro__click").style.display = "none";
      document.querySelector(".component-wrapper").style.opacity = 1;
      document.querySelector(".component-wrapper").style.pointerEvents = "all";
    }, 1500);
    setTimeout(() => {
      document.querySelector(".intro__introVideo").style.display = "block";
      document.querySelector(".intro__introVideo").style.opacity = "1";
    }, 500);
  };

  return (
    <section
      className="section section--intro"
      id="intro"
      data-0="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-7000="display:block;pointer-events:all;position:fixed;top:0;left:0"
      data-7001="display:none;pointer-events:none;position:fixed;top:0;left:0"
    >
      <div
        className="intro__background-2"
        data-0="width:100vw;left:0vw;top:0vh;height:100vh;opacity:1;"
        data-500="width:250vw;left:-75vw;top:-75vh;height:250vh;opacity:1;"
        data-520="opacity:0;"
      >
        <Image id="133d07a5-5d13-46dd-b644-7e92436fea5e" />
      </div>
      <div
        className="intro__introVideo"
        data-0="height: 25vh;top:37.5vh;opacity:1"
        data-500="height: 50vh;top:25vh;opacity:1"
        data-800="height: 60vh;top:20vh;opacity:1"
        data-1020="height: 150vh;top:-25vh;opacity:1"
        data-1080="height: 150vh;top:-25vh;opacity:0"
      >
        <Wrapper fromStep={-1} toStep={8}>
          <Video
            playing={videoA}
            src={
              "https://a.storyblok.com/f/107910/x/2e5eb43adb/bilderstrudel_lowerbitrate.mp4"
            }
            poster="https://a.storyblok.com/f/107910/601x600/88e29c5c4d/intro.png"
          ></Video>
        </Wrapper>
      </div>
      {!devMode && (
        <div
          className="intro__click"
          onClick={() => {
            setVideoA(true);
          }}
        >
          {/* <div className="intro__wrapper" data-anim="base wrapper">
            <div className="circle" data-anim="base left"></div>
            <div className="circle" data-anim="base right"></div>
          </div> */}
          <div className="intro__click__square" onClick={start}>
            <p>Click here to start</p>
          </div>
        </div>
      )}
      <div
        className="intro__presentationVideo"
        data-0="opacity:0;display:none;width:100vw;left:0px;top:0px"
        data-2000="opacity:0;display:block;width:100vw;left:0vw;top:0px"
        data-3000="opacity:1;display:block;width:100vw;left:0vw;top:0px"
        data-3150="opacity:1;display:block;width:100vw;left:0vw;top:0px"
        data-5950="opacity:1;width:50vw;display:block;left:0vw;top:0px"
        data-6200="opacity:1;width:50vw;display:block;left:0vw;top:0px"
        data-6700="opacity:1;width:50vw;display:block;left:-50vw;top:0px"
        data-7000="opacity:1;width:50vw;display:block;left:-100vw;top:0px"
      >
        <Wrapper fromStep={-1} toStep={8}>
          <Video
            src={
              "https://a.storyblok.com/f/107910/x/68c3fc8856/tangledgarden_intro_final.mp4"
            }
            poster={
              "https://a.storyblok.com/f/107910/889x500/bbe6c790d4/pres.png"
            }
            playing={videoB}
          ></Video>
        </Wrapper>
      </div>
      <div
        className="intro__floater intro__floater--1"
        data-0="left:100vw;top:30vh"
        data-1000="left:100vw;top:30vh"
        data-3000="left:-60vw;top:0vh"
      >
        <Image id="94b4c44a-ac3d-4066-95d9-577b09199f3c" />
      </div>
      <div
        className="intro__floater intro__floater--2"
        data-0="left:100vw;top:40vh"
        data-1000="left:100vw;top:40vh"
        data-3000="left:-60vw;top:80vh"
      >
        <Image id="fb8fd309-9b62-4249-bbf1-dd404a8ecb7c" />
      </div>
      <div
        className="intro__text"
        data-0="opacity:0;"
        data-300="opacity:1;"
        data-900="opacity:1;"
        data-1000="opacity:0;"
      >
        <h2>You are about to enter</h2>
        <h1>The Garden of Tangled Data</h1>
        <h2>Please put your headphones on</h2>
      </div>
    </section>
  );
};

// == Export
export default Introduction;
